@mixin darkThemeVariables {
  --primary-100: #e8e8fc;
  --primary-200: #d2d1f9;
  --primary-300: #bbbaf6;
  --primary-400: #a5a3f3;
  --primary: #8e8cf0;
  --primary-rgb: 142, 140, 240;
  --primary-600: #7270c0;
  --primary-700: #555490;
  --primary-800: #393860;
  --primary-900: #1c1c30;
  --secondary-100: #b1b1ce;
  --secondary-200: #8a8ab5;
  --secondary-300: #63639d;
  --secondary-400: #505090;
  --secondary: #3c3c84;
  --secondary-600: #282861;
  --secondary-700: #24244f;
  --secondary-800: #141333;
  --secondary-900: #0c0c1a;
  --tertiary-100: #f1e1f7;
  --tertiary-200: #e4c4f0;
  --tertiary-300: #d6a6e8;
  --tertiary-400: #c989e1;
  --tertiary: #bb6bd9;
  --tertiary-600: #9656ae;
  --tertiary-700: #704082;
  --tertiary-800: #4b2b57;
  --tertiary-900: #25152b;
  --success-100: #d3eadd;
  --success-200: #a6d5ba;
  --success-300: #7ac098;
  --success-400: #4dab75;
  --success: #219653;
  --success-600: #1a7842;
  --success-700: #145a32;
  --success-800: #0d3c21;
  --success-900: #071e11;
  --error-100: #facfd0;
  --error-200: #f49ea1;
  --error-300: #ef6e73;
  --error-400: #e93d44;
  --error: #e40d15;
  --error-600: #b60a11;
  --error-700: #89080d;
  --error-800: #5b0508;
  --error-900: #2e0304;
  --warning-100: #fcebdb;
  --warning-200: #fad6b7;
  --warning-300: #f7c292;
  --warning-400: #f5ad6e;
  --warning: #f2994a;
  --warning-600: #c27a3b;
  --warning-700: #915c2c;
  --warning-800: #613d1e;
  --warning-900: #301f0f;
  --typography-100: #fafafa;
  --typography-200: #e6e6e6;
  --typography-300: #cdcdcd;
  --typography-400: #9b9b9b;
  --typography: #828282;
  --typography-600: #686868;
  --typography-700: #4e4e4e;
  --typography-800: #343434;
  --typography-900: #1a1a1a;
  --star-100: #fae9b7;
  --star-300: #f6d982;
  --star: #f2c94c;
  --star-700: #a98d35;
  --star-900: #61501e;
  --chart-100: #bbebfa;
  --chart-300: #89dbf6;
  --chart: #56ccf2;
  --chart-700: #3c8fa9;
  --chart-900: #225261;
  --accent: #555bf6;
  --white-with-opacity-5: rgba(255, 255, 255, 0.05);
  --white-with-opacity-15: rgba(255, 255, 255, 0.15);
  --white-with-opacity-25: rgba(255, 255, 255, 0.25);
  --white-with-opacity-50: rgba(255, 255, 255, 0.5);
  --star-with-opacity-70: rgba(242, 201, 76, 0.7);
  --secondary-400-with-opacity-80: rgba(60, 60, 132, 0.8);
  --secondary-400-with-opacity-40: rgba(60, 60, 132, 0.4);
  --secondary-600-with-opacity-70: rgba(40, 40, 97, 0.7);
  --primary-with-opacity-30: rgba(142, 140, 240, 0.3);
  --primary-with-opacity-50: rgba(142, 140, 240, 0.5);
  --primary-400-with-opacity-30: rgba(165, 163, 243, 0.3);
  --primary-700-with-opacity-30: rgba(85, 84, 144, 0.3);
  --primary-900-with-opacity-40: rgba(28, 28, 48, 0.4);
  --warning-with-opacity-20: rgba(242, 153, 74, 0.2);
  --shadow: rgba(36, 36, 79, 0.56);
  --typography-200-with-opacity-50: rgba(230, 230, 230, 0.5);
  --gradient-pink-right-part: #f2994a;
  --gradient-pink-left-part: #6a4dbc;
  --gradient-pink-base: linear-gradient(90.6deg, #6a4dbc 0.47%, #f2994a 137.94%);
  --gradient-eclipse: linear-gradient(91deg, #b57ce2 0.47%, #f0aea8 137.94%);
  --gradient-tranquil: linear-gradient(83.5deg, #5e6ffd -15.87%, #aa8aca 96.3%);
  --gradient-azure: linear-gradient(38.65deg, #4568dc 27.36%, #b06ab3 87.78%);
  --gradient-amethyst: linear-gradient(180deg, #141333 0%, #202261 70.63%, #3a237a 100%);
  --gradient-light-blue: linear-gradient(180deg, rgba(69, 104, 220, 0.16) 0%, transparent 100%);
  --gradient-soft-blue: linear-gradient(180deg, rgba(69, 104, 220, 0.32) 0%, rgba(69, 104, 220, 0.08) 100%);
  --gradient-purple-surge: linear-gradient(0.26deg, rgba(69, 104, 220, 0) 0.27%, rgba(75, 94, 217, 0.1) 7.21%, rgba(81, 85, 214, 0.6) 18.04%, rgba(94, 63, 208, 0.85) 27.94%, #7716C3 55.82%);
  --primary-page-background: #141333;
  --alternative-page-background: #24244f;
  --white: #fff;
}

@mixin lightThemeVariables {
  --primary-100: #0c0b2b;
  --primary-200: #181656;
  --primary-300: #242281;
  --primary-400: #302dac;
  --primary: #3c38d7;
  --primary-rgb: 60, 56, 215;
  --primary-600: #6360df;
  --primary-700: #c5c3f3;
  --primary-800: #ecebfb;
  --primary-900: #f6f6ff;
  --secondary-100: #06060f;
  --secondary-200: #11122e;
  --secondary-300: #22255c;
  --secondary-400: #2e317a;
  --secondary: #393d99;
  --secondary-600: #6164ad;
  --secondary-700: #888bc2;
  --secondary-800: #c4c5e0;
  --secondary-900: #ebecf5;
  --tertiary-100: #25152b;
  --tertiary-200: #4b2b57;
  --tertiary-300: #704082;
  --tertiary-400: #9656ae;
  --tertiary: #bb6bd9;
  --tertiary-600: #c989e1;
  --tertiary-700: #d6a6e8;
  --tertiary-800: #e4c4f0;
  --tertiary-900: #f1e1f7;
  --success-100: #d3eadd;
  --success-200: #a6d5ba;
  --success-300: #7ac098;
  --success-400: #4dab75;
  --success: #219653;
  --success-600: #1a7842;
  --success-700: #145a32;
  --success-800: #0d3c21;
  --success-900: #071e11;
  --error-100: #facfd0;
  --error-200: #f49ea1;
  --error-300: #ef6e73;
  --error-400: #e93d44;
  --error: #e40d15;
  --error-600: #b60a11;
  --error-700: #89080d;
  --error-800: #5b0508;
  --error-900: #2e0304;
  --warning-100: #fcebdb;
  --warning-200: #fad6b7;
  --warning-300: #f7c292;
  --warning-400: #f5ad6e;
  --warning: #f2994a;
  --warning-600: #c27a3b;
  --warning-700: #915c2c;
  --warning-800: #613d1e;
  --warning-900: #301f0f;
  --typography-100: #1a1a1a;
  --typography-200: #343434;
  --typography-300: #4e4e4e;
  --typography-400: #686868;
  --typography: #828282;
  --typography-600: #9b9b9b;
  --typography-700: #cdcdcd;
  --typography-800: #e6e6e6;
  --typography-900: #fafafa;
  --star-100: #61501e;
  --star-300: #a98d35;
  --star: #f2c94c;
  --star-700: #f6d982;
  --star-900: #fae9b7;
  --chart-100: #225261;
  --chart-300: #3c8fa9;
  --chart: #56ccf2;
  --chart-700: #89dbf6;
  --chart-900: #bbebfa;
  --accent: #555bf6;
  --white-with-opacity-5: rgba(60, 56, 215, 0.05);
  --white-with-opacity-15: rgba(60, 56, 215, 0.15);
  --white-with-opacity-25: rgba(60, 56, 215, 0.25);
  --white-with-opacity-50: rgba(60, 56, 215, 0.5);
  --primary-with-opacity-50: rgba(142, 140, 240, 0.5);
  --secondary-600-with-opacity-70: rgba(40, 40, 97, 0.7);
  --typography-200-with-opacity-50: rgba(52, 52, 52, 0.5);
  --primary-900-with-opacity-40: rgba(246, 246, 255, 0.4);
  --warning-with-opacity-20: rgba(242, 153, 74, 0.2);
  --shadow: rgba(36, 36, 79, 0.56);
  --gradient-pink-right-part: #f2994a;
  --gradient-pink-left-part: #6a4dbc;
  --gradient-pink-base: linear-gradient(90.6deg, #6a4dbc 0.47%, #f2994a 137.94%);
  --gradient-eclipse: linear-gradient(91deg, #b57ce2 0.47%, #f0aea8 137.94%);
  --gradient-tranquil: linear-gradient(83.5deg, #5e6ffd -15.87%, #aa8aca 96.3%);
  --gradient-azure: linear-gradient(38.65deg, #4568dc 27.36%, #b06ab3 87.78%);
  --gradient-amethyst: linear-gradient(180deg, #141333 0%, #202261 70.63%, #3a237a 100%);
  --gradient-light-blue: linear-gradient(180deg, rgba(69, 104, 220, 0.16) 0%, transparent 100%);
  --gradient-soft-blue: linear-gradient(180deg, rgba(69, 104, 220, 0.32) 0%, rgba(69, 104, 220, 0.08) 100%);
  --gradient-purple-surge: linear-gradient(0.26deg, rgba(69, 104, 220, 0) 0.27%, rgba(75, 94, 217, 0.1) 7.21%, rgba(81, 85, 214, 0.6) 18.04%, rgba(94, 63, 208, 0.85) 27.94%, #7716C3 55.82%);
  --primary-page-background: #fff;
  --alternative-page-background: #fff;
  --white: #fff;
}
